import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import NavigationBar from './components/NavBar/NavigationBar';
import Loading from './components/Loading/Loading';

const Header = lazy(() => import('./components/Header/Header'));
const WhatWeDo = lazy(() => import('./components/WhatWeDo/WhatWeDo'));
const OurProcess = lazy(() => import('./components/OurProcess/OurProcess'));
const OurPrinciples = lazy(() => import('./components/OurPrinciples/OurPrinciples'));
const Modal = lazy(() => import('./components/Modal/Modal'));
const Contact = lazy(() => import('./components/Contact/Contact'));
const Footer = lazy(() => import('./components/Footer/Footer'));

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Simulate an API call or other loading tasks
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // 2 seconds
  }, []);

  return (
    <div className="app">
      {isLoading ? (
        <Loading />
      ) : (
        <Suspense fallback={<Loading />}>
          <Router>
            <NavigationBar onContactClick={() => setShowModal(true)} />
            <Modal showModal={showModal} setShowModal={setShowModal} />
            <Header showModal={showModal} setShowModal={setShowModal} />
            <Modal showModal={showModal} setShowModal={setShowModal} />
            <WhatWeDo />
            <OurProcess />
            <OurPrinciples />
            <Contact showModal={showModal} setShowModal={setShowModal} />
            <Modal showModal={showModal} setShowModal={setShowModal} />
            <Footer />
            {/* Define your routes here */}
            {/* <Switch>
               ...
             </Switch> */}
          </Router>
        </Suspense>
      )}
    </div>
  );
};

export default App;
