import React, { useState, useEffect } from 'react';
import './NavigationBar.css';
import AlienLogo from './alien.svg'

const NavigationBar = ({ onContactClick }) => {
  const [isOpen, setIsOpen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogoClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="nav-logo" onClick={handleLogoClick}>
        <img src={AlienLogo} alt="Alien Logo" />
        <a href="#top" className="logo-link">Skyquake Software</a>
      </div>
      {isOpen && (
        <ul className="nav-links">
          <li>
            <a href="#what-we-do">Our Passion</a>
          </li>
          <li>
            <a href="#our-process">Our Process</a>
          </li>
          <li>
            <a href="#contact" onClick={onContactClick}>Contact</a>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default NavigationBar;
