import React from 'react';
import logo from './loading.png';
import './Loading.css';

const Loading = () => {
    const quotes = [
            "Reticulating splines...",
            "Generating witty dialog...",
            "Swapping time and space...",
            "Spinning violently around the y-axis...",
            "Tokenizing real life...",
            "Bending the spoon...",
            "Don't think of purple hippos...",
            "We need a new fuse...",
            "Have a good day.",
            "The architects are still drafting",
            "The bits are breeding",
            "We're building the buildings as fast as we can",
            "...and enjoy the elevator music...",
            "Please wait while the little elves draw our site",
            "Don't worry - a few bits tried to escape, but we caught them",
            "Checking the gravitational constant in your locale...",
            "Go ahead -- hold your breath!",
            "...at least you're not on hold...",
            "You're not in Kansas any more",
            "The server is powered by a lemon and two electrodes.",
            "Please wait while a larger software vendor in Seattle takes over the world",
            "Initializing Patience Test",
            "Follow the white rabbit",
            "While the satellite moves into position",
            "keep calm and npm install",
            "The bits are flowing slowly today",
            "Testing on Timmy... We're going to need another Timmy.",
            "Reconfoobling energymotron...",
            "(Insert quarter)",
            "Are we there yet?",
            "Have you lost weight?",
            "Just count to 10",
            "Counting backwards from Infinity",
            "Don't panic...",
            "Embiggening Prototypes",
            "Do you come here often?",
            "We're making you a cookie.",
            "Creating time-loop inversion field",
            "Spinning the wheel of fortune...",
            "Loading the enchanted bunny...",
            "Computing chance of success",
            "I'm sorry Dave, I'm still loading.",
            "Looking for exact change",
            "All I really need is a kilobit.",
            "I feel like im supposed to be loading something. . .",
            "What do you call 8 Hobbits? A Hobbyte.",
            "Adjusting flux capacitor...",
            "Unicorns are at the end of this road, I promise.",
            "Listening for the sound of one hand clapping...",
            "Keeping all the 1's and removing all the 0's...",
            "Putting the icing on the cake. The cake is not a lie...",
            "Cleaning off the cobwebs...",
            "Making sure all the i's have dots...",
            "We need more dilithium crystals",
            "Granting wishes...",
            "Time flies when you’re having fun.",
            "Spinning the hamster…",
            "99 bottles of beer on the wall..",
            "Stay awhile and listen..",
            "Be careful not to step in the git-gui",
            "Load it and they will come",
            "Convincing the AI not to turn evil..",
            "There is no spoon. Because we are not done loading it",
            "Your left thumb points to the right and your right thumb points to the left.",
            "Wait, do you smell something burning?",
            "Computing the secret to life, the universe, and everything.",
            "When nothing is going right, go left!!...",
            "Optimism – is a lack of information.....",
            "I think I am, therefore, I am. I think.",
            "git happens",
            "May the forks be with you",
            "A commit a day keeps the mobs away",
            "Constructing additional pylons...",
            "Roping some seaturtles...",
            "Locating Jebediah Kerman...",
            "If you type Google into Google you can break the internet",
            "What is the airspeed velocity of an unladen swallow?",
            "The Elders of the Internet would never stand for it.",
            "Space is invisible mind dust, and stars are but wishes.",
            "Didn't know paint dried so quickly.",
            "I didn't choose the engineering life. The engineering life chose me.",
            "Dividing by zero...",
            "Spawn more Overlord!",
            "Some days, you just can’t get rid of a bug!",
            "We’re going to need a bigger boat.",
            "Chuck Norris never has to git push; The repo pulls before.",
            "Web developers do it with <style>",
            "Please wait while we git pull --our-life-together",
            "Java developers never RIP. They just get Garbage Collected.",
            "Cracking military-grade encryption...",
            "Simulating traveling salesman...",
            "Proving P=NP...",
            "Entangling superstrings...",
            "Twiddling thumbs...",
            "Searching for plot device...",
            "Trying to sort in O(n)...",
            "Laughing at your pictures-i mean, loading...",
            "Looking for sense of humour, please hold on.",
            "Please wait while the intern refills his coffee.",
            "A different error message? Finally, some progress!",
            "Hold on while we wrap up our git together...sorry",
            "Please hold on as we reheat our coffee",
            "Kindly hold on as we convert this bug to a feature...",
            "Kindly hold on as our intern quits vim...",
            "Winter is coming...",
            "Switching to the latest JS framework...",
            "Distracted by cat gifs",
            "Finding someone to hold my beer",
            "Let's hope it's worth the wait",
            "Aw, snap! Not..",
            "Ordering 1s and 0s...",
            "Whatever you do, don't look behind you...",
            "Please wait... Consulting the manual...",
            "It is dark. You're likely to be eaten by a grue.",
            "Loading funny message...",
            "It's 10:00pm. Do you know where your children are?",
            "Feel free to spin in your chair",
            "What's under there?",
            "Go ahead, hold your breath and do an ironman plank till loading complete",
            "Help, I'm trapped in a loader!",
            "Initializing the initializer...",
            "When was the last time you dusted around here?",
            "Optimizing the optimizer...",
            "Last call for the data bus! All aboard!",
            "Never let a computer know you're in a hurry.",
            "A computer will do what you tell it to do, but that may be much different from what you had in mind.",
            "Shovelling coal into the server",
            "Pushing pixels...",
            "How about this weather, eh?",
            "Everything in this universe is either a potato or not a potato",
            "Updating Updater...",
            "Downloading Downloader...",
            "Debugging Debugger...",
            "Digested cookies being baked again.",
            "Live long and prosper.",
            "Running with scissors...",
            "You seem like a nice person...",
            "Time flies like an arrow; fruit flies like a banana",
            "Two men walked into a bar; the third ducked...",
            "Sorry we are busy catching em' all, we're done soon",
            "TODO: Insert elevator music",
            "Still faster than Windows update",
            "Please wait while the minions do their work",
            "Grabbing extra minions",
            "Doing the heavy lifting",
            "Waking up the minions",
            "You are number 2843684714 in the queue",
            "Feeding unicorns...",
            "Rupturing the subspace barrier",
            "Creating an anti-time reaction",
            "Converging tachyon pulses",
            "Bypassing control of the matter-antimatter integrator",
            "Adjusting the dilithium crystal converter assembly",
            "Reversing the shield polarity",
            "Disrupting warp fields with an inverse graviton burst",
            "Up, Up, Down, Down, Left, Right, Left, Right, B, A.",
            "Do you like my loading animation? I made it myself",
            "Whoah, look at it go!",
            "One mississippi, two mississippi...",
            "Don't panic... AHHHHH!",
            "Ensuring Gnomes are still short.",
            "Baking ice cream...",
          ];

    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];

    return (
        <div className="loading-screen">
            <img src={logo} alt="logo" className="loading-logo" />
            <h2>Loading</h2>
                <h1>Skyquake Software</h1>
            <div className="loading-quote"><p>{randomQuote}</p></div>
        </div>
    )
};

export default Loading;
